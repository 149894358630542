.img-placeholder {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: dashed 1px var(--bs-gray-80);
}

.tooltip-error {
  .tooltip-inner {
    background-color: var(--bs-red);
  }

  .tooltip-arrow {
    &::before {
      border-left-color: var(--bs-red);
    }
  }
}

.modal-mmd {
  max-width: 43.25rem !important;
}

.modal-backdrop.-darken {
  opacity: 0.85;
}
